body {
  font-family: 'PF BeauSans Pro', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  line-height: normal;
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

:root {
  --blue: #1c556e;
  --red: #92241f;
  --yellow-priority: #f7c90e;
  --blue-priority: #05b9be;
  --yellow-secondary: #eabc02;
  --gray-color-gray: #9c9c9c;
  --gray-color-gray-200: #d9dada;
  --gray-color-black: #2b2a29;
  --gray-color-gray-100: #efefef;
  --gray-color-white: #fefefe;
  --text-main-text: #111;
  --text-disable: #aaa;
  --sysyem-color-system-sucess: #00be64;
  --sysyem-color-system-warning: #ff7500;
  --sysyem-color-system-error: #ef4545;
  --functional-error: #ef4545;
  --functional-second-error: #ffe5e1;
  --rd-s: 4px;
}

@import url(../src/vendor/normalize.css);
@import url(../src/vendor/fonts.css);

* {
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}