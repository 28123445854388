.Gallery {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3%;
  }
  
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    height: 100%;
    object-fit: scale-down;
  }
  body{
    width: 100%;
    height: 100%;
}
#Work_screen{
    max-width: 280px;
    height: auto;
    object-fit: scale-down;
}
#Workpage_paper_plane{
    width: 30%;
    position: absolute;
    right: 0;
    top: 90px;
}
#Work_name{
    font-family: "PFBeauSansProBold";
    font-size: 36px;
    margin-left: 7%;
    margin-top: 3%;
    display: flex;
}
#Module_information{
    font-family: "PFBeauSansProLight";
    margin-left: 7%;
    margin-top: 2%;
}

#Module_name{
    font-family: "PFBeauSansProLight";
    text-decoration-line: underline;
    font-size: 20px;
    line-height: 24px;
}

#Show_information{
    font-family: "PFBeauSansProLight";
    font-size: 13px;
    color: #8A8A8A;
    margin-left: 7%;
    margin-top: 3%;
    
}
#Show_information:hover{
    color: black;
    filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.518));
}

#Autor_avatar{
    width: 70px;
    height: 70px;
    border-radius: 100%; 
    object-fit: cover;
    grid-column-start: 1;
    grid-column-end: 1;

}
#Work_data{
    width: 82%;
    display: grid;
    height: fit-content;
    grid-template-columns: 70px 1fr;
    grid-template-rows: 1fr;
    margin-top: 3%;
    margin-left: 7%;
}
#Work_data2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;
}
#Comments{
    font-size: 15px;
    height: fit-content;
    font-family: "PFBeauSansProLight";
    word-wrap: break-word;
    width: 55%;

}
#Autor_name_surname{
    font-size: 20px;
    font-family: "PFBeauSansProBold";

    margin-bottom: 10px;
}


#adminBtn {
    width: 90%;
    height: fit-content;
    margin-left: 7%;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    
}

#Download_button{
    border: none;
    width: 200px;
    height: 36px;
    background: #F7C90E;
    border-radius: 20px;
    font-size: 16px;
    font-family: "PFBeauSansProBold";
}

#Download_button:hover {
    background-color: #ffd52b;
    filter: drop-shadow(0 0 0.75rem rgba(255, 219, 37, 0.575));
}

#Edit_work_button{
    width: 200px;
    height: 36px;
    background: #05B9BE;
    
    border: none;
    border-radius: 20px;
    font-size: 16px;
    font-family: "PFBeauSansProBold";
    margin-right: 15px;
}

#Edit_work_button:hover {
    background-color: #06c3ca;
    filter: drop-shadow(0 0 0.75rem rgba(15, 195, 195, 0.518));
}

#approveBtn{
    width: 200px;
    height: 36px;
    background-color: #F7C90E;
    font-size: 16px;

    border: 0ch;
    border-radius: 20px;
    font-size: 16px;
    font-family: "PFBeauSansProBold";
}

#approveBtn:hover {
    background-color: #ffd52b;
    filter: drop-shadow(0 0 0.75rem rgba(195, 189, 15, 0.518));
}


.Moduletopic{
    margin-left: 7%;
    margin-top: 1%;
    width: 60%;
    display: flex;
    resize: none;
    word-wrap: break-word;
}
#Aboutmodule{
  font-family: "PFBeauSansProLight";
  display: inline-block;
  width: 100%;
  height: fit-content;
  word-wrap: break-word;
}
#datascreen{
    object-fit: scale-down;
}