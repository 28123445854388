.status-publication {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: var(--blue);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}
.status-publication svg {
  width: 12px;
  height: 12px;
}
