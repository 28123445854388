.activeClass {
    width: fit-content;
    height: fit-content;

    margin: 25px 15px;
    padding: 10px 20px;
    border: 0cm;
    font-size: 15px;
    font-family: "PFBeauSansProBold";
    border-radius: 20px;
    background-color: #F7C90E;
}

.activeClass:hover {
    background-color: #ffd52b;
    filter: drop-shadow(0 0 0.75rem rgba(220, 199, 43, 0.518));
}

.notActiveClass {
    width: fit-content;
    height: fit-content;

    margin: 25px 15px;
    padding: 10px 20px;
    border: 0cm;
    font-size: 15px;
    font-family: "PFBeauSansProBold";
    border-radius: 20px;
    background-color: #05B9BE;
}

.notActiveClass:hover {
    background-color: #06c3ca;
    filter: drop-shadow(0 0 0.75rem rgba(38, 180, 205, 0.518));
}


#admin_buttons {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.AdminBtn {
    width: fit-content;
    height: fit-content;

    margin: 25px 0px;
    padding: 10px 20px;
    border: 0cm;
    font-size: 15px;
    font-family: "PFBeauSansProBold";
    border-radius: 20px;
}
.confirm_adding{
    width: 23%;
    height: 36px;
    background: #05B9BE;;
    border: 0cm;
    font-size: 15px;
    font-family: "PFBeauSansProBold";
    border-radius: 20px;
    margin-top: 3%;
    margin-left: 38.5%;
    margin-bottom: 25px;
}

.confirm_adding:hover {
    background-color: #06c3ca;
    filter: drop-shadow(0 0 0.75rem rgba(38, 180, 205, 0.518));
}

#H_add_student{
    text-align: center;
    font-family: "PFBeauSansProBold";
    font-size: 36px;
}

#avatar{
    width: 100%;
    height: 100%;
    border-radius: 100%; 
    object-fit: cover;
    grid-column-start: 1;
    grid-column-end: 1;
}
#account_avatar{
    width: 150px;
    height: 150px;
}
#student_data{
    display: grid;
    width: 70%;
    height: fit-content;
    grid-template-columns: 0.5fr 2fr 2fr 2fr;
    grid-template-rows: 1fr;
    margin-top: 35px;
    margin-left: 2%;
    align-items: flex-start;
    text-align: left;
}
#student_data2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -5px;;
    grid-column-start: 2;
    grid-column-end: 2;
}
#student_data3{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;
    grid-column-start: 3;
    grid-column-end: 3;
}

#student_data4{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;
    grid-column-start: 4;
    grid-column-end: 4;
}

 #module_about, #module_name, #student_name, #student_email, #birth_date, #password{
    font-size: 20px;
    font-family: "PFBeauSansProBold";
}

#student_email, #password {
    margin-top: 10px;
}

.student_data_input{
    padding-left: 10px;
    width: 70%;
    height: 23px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 3px;
    margin-top: 5px;
  }
  #module{
    width: fit-content;
    margin-top: 2%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
  }
  #module_add{
    width: auto;
    height: auto;
  }
  #modules_grid{
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
    height: 40%;
    display: flex;
    justify-content:flex-start;

    flex-direction: row;
    flex-wrap: wrap;
    height: fit-content;
  }

  .aboutModule {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin: 30px 0px 0px 45px;
  }

  #nameModule, #aboutModule {
    resize: none;
    border: none;
    border: 1px solid #000000;
    border-radius: 3px;
    padding: 5px;
  }

  #nameModule {
    margin: 5px 0px 20px 0px;
    height: 23px;
    width: 50%;
  }

  #aboutModule {
    width: 50%;
    height: 280px;
    margin: 5px 0px 0px 0px;
  }

  