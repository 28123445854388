.gallery {
  display: flex;
  flex-direction: column;
  padding: 58px 130px 40px;
  margin: 0 auto;
  max-width: 1440px;
}
.gallery__title {
  color: var(--gray-color-black, #2b2a29);
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  margin: 0 0 48px;
}

.gallery__search {
  margin: 0;
  padding: 0;
}

.gallery__cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 столбца с равным распределением ширины */
  justify-content: center; /* Центрирует элементы по горизонтали */
  align-items: center; /* Центрирует элементы по вертикали */
  gap: 20px;
  margin: 40px 0;
  padding: 0;
  list-style: none;
}



