.card {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin: 0;
  padding: 0;
  max-width: 280px;
  width: 100%;
  aspect-ratio: 2.8 / 3.6;
  transform: scale(1);
  transition: transform 0.2s;
  cursor: pointer;
}
.card:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}
.card__img {
  max-width: 280px;
  max-height: 280px;
  width: 100%;
  border-radius: 20px;
  background: #d9d9d9;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  margin: 0;
  padding: 0;
  outline: 1px solid var(--blue-priority);
}
.card__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--rd-s);
  margin: 0;
  padding: 0;
}

.card__title {
  color: var(--blue);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
  text-transform: capitalize;
}
.card__module {
  max-width: 280px;
  color: var(--blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__author {
  max-width: 280px;
  color: var(--blue, #1c556e);
  font-family: PF BeauSans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}
