@font-face {
  font-family: "PFBeauSansPro";
  font-style: normal;
  font-weight: 300;
  src:
    local("PFBeauSansPro"),
    url('../../../style/fonts/PFBeauSansPro-Light.ttf');
}
@font-face {
  font-family: "PFBeauSansPro_bold";
  font-style: bold;
  font-weight: 300;
  src:
    local("PFBeauSansPro"),
    url('../../../style/fonts/PFBeauSansPro-SemiBold.ttf');
}


.modal_forget_data {
  font-family: "PFBeauSansPro";
  margin-top: 272px;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 480px;
  height: 240px;

}

.header_modal {
  background-color: #05B9BE;
  width: 100%;
  height: 48px;
}

.header_modal button {
  border: 0px;
  background-color: #05B9BE;
  width: 20px;
  margin-left: 440px;
  margin-right: 14px;
  padding-top: 14px;
  height: 20px;
  flex-shrink: 0;
}

.content {
  overflow-y: auto;
}

.close {
  align-self: flex-end;
  width: 40px;
  height: 40px;
  margin: -24px;
  color: #000
}


#login_form {
  width: 340px;
  height: 334px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  font-family: "PFBeauSansPro";

}

h2 {
  font-family: "PFBeauSansPro_bold";
  color: var(--black, #2B2A29);
  font-size: 24px;
  font-style: bold;
  font-weight: 600;
  line-height: 30px;
}

.normal_text {
  color: var(--black, #2B2A29);
  font-family: "PFBeauSansPro";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  /* 142.857% */
}

.normal_text>img {
  vertical-align: middle;
  margin-left: 8px;
}

.first_normal_text {
  margin-top: 40px;
  margin-bottom: 12px;
}

.normal_text a {
  cursor: pointer;
 text-decoration: underline;
}