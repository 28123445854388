.Autor_name {
    margin-top: 12px;
    font-size: 18px;
    color: #1C556E;
    line-height: 24px;
    font-weight: 600;
    color: var(--blue, #1C556E);
    font-family: 'PFBeauSansPro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.Work_name_module {
    font-size: 18px;
    color: var(--blue, #1C556E);
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    font-family: "PFBeauSansPro";
}
.Work_grid:hover{
    transform: scale(1.05);
    transition: transform 0.2s;
}
.Work_Screen{
    border:#05B9BE solid thin ;
    width: 280px;
    height: 280px;
    border-radius: 20px;
    object-fit: cover;;
}

.listItem__div__status{
    width: 12px ;
    height: 12px;
    border-radius: 12px;
    margin-right: 6px;
}
.listItem__div__cont_status{
    margin-top: 3%;
    display: flex;
    color: var(--blue, #1C556E);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    font-family: "PFBeauSansPro";
    align-items: center;
}