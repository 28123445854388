.container-panel-students {
    padding-top: 40px;
}

.container-panel-students__title {
    color: var(--Gray-color-black, #2B2A29);
    font-family: "PFBeauSansPro_bold";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 8px;
}

.container-list-students {
    margin-top: 3.2%;
}

.container-panel-students__btn-add-students {
    cursor: pointer;
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: var(--blue-priority, #05B9BE);
    color: var(--Gray-color-white, #FEFEFE);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border: 0;
    margin-top: 4%;
    /* 120% */
}

.container-panel-students__btn-add-students:hover {
    background: #009ca0;
    transition: background 0.2s;
}

.container-list-student {
    width: 100%;
    padding: 0;
    justify-content: space-between;
    color: var(--blue, #1C556E);
    display: flex;
    font-family: 'PFBeauSansPro_bold';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    border-bottom: solid thin #D9D9D9;
    padding-bottom: 13px;
    padding-top: 13px;

}

.container-btn {
    display: flex;
}

.container-btn__change {
    cursor: pointer;
    margin-right: 25%;
}

.container-btn__delete {
    cursor: pointer;
}

.container-panel-students__download-students {
    cursor: pointer;
    color: var(--blue-priority, #05B9BE);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    padding: 8px 20px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid var(--blue-priority, #05B9BE);
    background: var(--Gray-color-white, #FEFEFE);
    margin-left: 20px;
}
.container-panel-students__download-students:hover{
    border: 1px solid var(--blue-priority, #04878b);
    color: var(--blue-priority, #035154);

}

.container-panel-students-btn {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
   
}