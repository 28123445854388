.container-admin-panel {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 4.63%;
}

.container-admin-panel__title {
    color: var(--Gray-color-black, #2B2A29);
    
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    /* 110% */
}


.custom-radio {

    display: inline-block;
    cursor: pointer;
    color: #1C556E;
    font-size: 20px;
    line-height: 24px;
    padding: 8px 20px 8px 20px;
    gap: 10px;
}

.checked {
    color: white;
    background-color: #05B9BE;
    border-radius: 30px;
}

input[type="radio"] {
    display: none;
}

.container-ModuleBar {
    display: flex;
    font-family: "PFBeauSansPro_bold";
    margin-top: 40px;
}