.MobileDevice {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30vh;
}

.MobileDevice__main-text {
    color: var(--blue, #1C556E);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

.MobileDevice__secondary-text {
    margin-top: 20px;
    color: var(--blue, #1C556E);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}