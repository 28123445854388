@font-face {
  font-family: "PFBeauSansPro";
  font-style: normal;
  font-weight: 300;
  src:
    local("PFBeauSansPro"),
    url('../../style/fonts/PFBeauSansPro-Light.ttf');
}

#login_form {
  width: 340px;
  height: 334px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  font-family: "PFBeauSansPro";

}

h2 {
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  font-size: 24px;
}

.label_text {
  line-height: 30px;
  font-size: 16px;


}

.login_input {

  width: 340px;
  height: 48px;
  border: solid 1px #05B9BE;
  padding: 8px, 16px, 8px, 16px;
  gap: 8px;
  border-radius: 20px;

}


.label_checkbox {
  font-size: 12px;
  line-height: 16px;
  color: #05B9BE;
  vertical-align: middle;
  display: flex;
  align-items: center;

}

.footer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  margin-top: 12px;
  line-height: 16px;
}

.footer a {
  text-align: right;
  text-decoration: underline;
  color: #05B9BE;
  font-size: 12px;
}

.login_submit {
  font-weight: bold;
  border-radius: 20px;
  background-color: #F7C90E;
  font-size: 20px;
  border: 0px;
  width: 360px;
  height: 48px;
}

input[type=checkbox] {
  background-color: #05B9BE;
}

input {
  padding-left: 12px;
  font-size: 12px;
  color: #05B9BE;
  line-height: 24px;
}

form {
  margin-top: 28px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon span {
  position: absolute;
  right: 0;
  top: 30%;
  padding: 14px 13px;
  transition: 0.3s;
}

.custom-icon {
  color: #898989;
}

.normal_text {
  color: var(--black, #2B2A29);
  font-family: "PFBeauSansPro";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  /* 142.857% */
}

.error_text {
  margin-left: 2%;
  margin-top: 2%;
  color: var(--functional-error, #EF4545);
  font-family: 'PFBeauSansPro';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  /* 133.333% */
}