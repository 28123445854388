body.active-modal {
    overflow-y: hidden;
}

#loading {
    width: 80px;
    margin-top: 33%;
}

.addButton {
    height: 48px;
    white-space: nowrap;
    padding: 0px 35px;
    background-color: #F7C90E;
    border: 0cm;
    border-radius: 200px;
    font-size: 20px;
    line-height: 24px;
    font-family: "PFBeauSansProBold";
    display: flex;
    width: 280px;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.addButton:hover {
    background-color: #06c3ca;

    filter: drop-shadow(0 0 0.75rem rgba(38, 180, 205, 0.518));

}

.modal,
.overlay {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.modal-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #F7C90E;
    border-radius: 10% 10% 3% 3%;
    min-width: 704px;
    height: 500px;

}

#page6 {
    position: absolute;
    left: 50%;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 704px;
    height: 500px;
}

.changeContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 45px;
    font-size: 25px;
    font-family: "PFBeauSansProBold";
}

.workName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;
}

#workName {
    height: 35px;
    width: 300px;
    font-family: "PFBeauSansProLight";
    text-align: center;
    border-radius: 7px;
}

.nextButton {
    position: absolute;
    top: 210px;
    left: 641px;
    border: 0cm;
    padding: 35px;
}

.nextButton:hover {
    scale: 1.15;
    filter: brightness(1.1);
    filter: drop-shadow(0 0 0.75rem rgba(20, 150, 220, 0.518));
}

.prevButton:hover {
    scale: 1.15;
    filter: brightness(1.1);
    filter: drop-shadow(0 0 0.75rem rgba(20, 150, 220, 0.518));
}

.prevButton {
    position: absolute;
    top: 210px;
    right: 640px;
    border: 0cm;
    padding: 35px;
}


.closeButton {
    position: absolute;
    top: -30px;
    right: -35px;
    padding: 16px;
    border: 0cm;
}

.closeButton:hover {
    scale: 1.15;
    filter: brightness(1.1);
    filter: drop-shadow(0 0 0.75rem rgba(241, 52, 52, 0.518));
}

h2 {
    font-size: 35px;
}



#aboutWork {
    width: 70%;
    height: 100px;
    background-color: rgba(255, 248, 248, 0.62);
    border: solid black 2px;
    border-radius: 10px;
    resize: none;
}

.back {
    background-color: white;
    position: fixed;
}

.cover {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#files {
    font-family: "PFBeauSansProBold";
    height: 30px;
    position: absolute;
    top: 353px;
    left: 289px;
}

.steps {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 704px;
    margin-top: 8%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #F7C90E;
    border-radius: 5% 5% 0% 0%;

    height: 500px;
}

.stepName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 85px;
    font-size: 25px;
    font-family: "PFBeauSansProBold";
}

.stepContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;
}



.doneWork {
    background-color: #1CBBC0;
    height: 40px;
    width: fit-content;
    padding: 0px 25px;
    border: 0em;
    border-radius: 20px;
    font-family: "PFBeauSansProBold";
    font-size: 15px;
}

.doneWork:hover {
    background-color: #06c3ca;
}

#nameWork {
    width: 60%;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 30px;
}

.leftPlane {
    width: 22%;
    position: absolute;
    left: 0;
    top: 60px;
}

.rightPlane {
    height: 50%;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

#Carousel_4step {
    margin-top: -150px;
    max-height: 100px;
    content: scale-down;
    align-items: center;
    text-align: center;

}

#Screens4step {
    max-height: 150px;
    width: 500%;
}

.screen {
    position: absolute;
    margin-left: 290px;
    border-radius: 10px;
    opacity: 0;
    background-color: #36dee400;
}

.screen:hover~#fake_button {

    filter: drop-shadow(0 0 0.75rem rgba(20, 150, 220, 0.518));

}

#fake_button {
    position: absolute;
    margin-left: 280px;
    top: 466px;
    z-index: 0;

}

#fake_button2 {
    position: absolute;
    top: 350px;
    left: 285px;
}

.screen-button {
    width: 100%;
    max-width: 290px;
    height: 60px;
    background: #1bbc9c00;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
}

#addfile {
    font-family: "PFBeauSansProBold";
}

input {
    writing-mode: horizontal-tb !important;
    font-family: "PFBeauSansProBold";
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: field;
    margin: 0em;
    color: #4b4a4a;
    border-image: initial;
}

.input_file {
    opacity: 0;
    z-index: 2;
    position: absolute;
}

#fake_button3 {
    position: absolute;
    z-index: 0;

}

#fake_button4 {
    position: absolute;
    margin-top: 140px;
    margin-left: 230px;

}

#screen2 {
    position: absolute;
    top: 470px;
    z-index: 1;
}

#edit_screen {
    position: absolute;
    margin-top: 310px;
    margin-left: 240px
}