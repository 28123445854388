.main-container {
  width: 45%;
  margin-left: 27.5%;
  display: flex;
  align-items: center;
  text-align: center;
  object-fit: scale-down;
  margin-bottom: 3%;
}

.window {
  max-height: 280px;
  overflow: hidden;
  width:100%;
  object-fit: scale-down;
  display: flex;
  align-items: center;
  text-align: center;
}

.all-pages-container {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  object-fit: scale-down;
  transition: translate;
  transition-property: transform;
  transition-timing-function: ease-in-out;
  width:100%;
}

.arrow {
  cursor: pointer;
}
