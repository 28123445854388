.container_add_new_work {
    overflow-y:auto;
    font-family: "PFBeauSansPro";
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%);
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    width: 940px;
    height: 874px;

}

.container_add_new_work::-webkit-scrollbar { width: 0 !important }

.form_add_new_work {
    display: flex;
    flex-direction: column;
    grid-gap: 35px;
}


.form_uploader_work {
    padding: 0 110px;
}

.header_add_new_work {
    background-color: #05B9BE;
    width: 100%;
    height: 48px;
}

.header_add_new_work button {
    border: 0px;
    background-color: #05B9BE;
    display: block;
    margin-left: auto;
    padding-top: 1.49%;
    padding-left: 0px;
    margin-right: 2%;
}

.content {
    overflow-y: auto;
}

.close {
    align-self: flex-end;
    width: 40px;
    height: 40px;
    margin: -24px;
    color: #000
}


.title {
    font-family: "PFBeauSansPro_bold";
    color: var(--black, #2B2A29);
    font-size: 40px;
    font-style: bold;
    font-weight: 600;
    line-height: 30px;
}

.normal_text {
    color: var(--black, #2B2A29);
    font-family: "PFBeauSansPro";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    text-align: center;
    /* 142.857% */
}

.normal_text>img {
    vertical-align: middle;
    margin-left: 8px;
}

.text_field {
    display: flex;
    justify-content: space-between;
}

.list_module {
    margin-top: 12px;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column
}

.select_module {
    width: 340px;
    height: 3.125em;
    border: solid 1px #05B9BE;
    padding: 8px, 16px, 8px, 16px;
    gap: 8px;
    border-radius: 20px;
    font-size: 16px;
    color: var(--blue, #1C556E);
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    font-family: "PFBeauSansPro";
    outline: none;
}

.select_module option {
    background-color: #fff;
    border-radius: 230px;
    color: #333;
}

.modal__close_img:hover {
    transform: scale(1.3);
    transition: transform 0.2s;
}

.file-input-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.file-input-label {
    line-height: 30px;
    font-size: 16px;
    margin-top: 12px;
    font-family: "PFBeauSansPro";
    justify-content: space-evenly;
    display: flex;
    flex-direction: column
}

.file-input {
    opacity: 0;
    display: none;
    margin-top: 222px;
    left: 0;
    top: 0;
}

.file-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 340px;
    height: 48px;
    border: solid 1px #05B9BE;
    padding: 8px, 16px, 8px, 16px;
    gap: 8px;
    border-radius: 20px;
    font-size: 16px;
    color: var(--blue, #1C556E);
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    font-family: "PFBeauSansPro";
    outline: none;
}

/* Стили для текста, когда файл не выбран */
.file-info span {
    color: var(--blue, #1C556E);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    /* 150% */
    margin-left: 16px;
}

.uploader_file {
    display: flex;
}

.file_upload_btn {
    max-width: 177px;
    display: flex;
    gap: 10px;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: var(--blue-priority);
    color: var(--gray-color-white);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border: none;
    outline: none;
    width: min-content;
    cursor: pointer;
    margin-left: 8px;
}

.file_upload_btn:hover {
    background: #009ca0;
    transition: background 0.2s;
}

.mark_for_size_file {
    margin-left: 1%;
    color: var(--gray-color-gray, #9C9C9C);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.screenshots_gellary_container {
    margin-top: 16px;
    display: flex;
}

.gellary_screenshots {
    display: flex;
}

.box-with-image {
    border-radius: 8px;
    margin-right: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-size: cover;
    background-repeat: no-repeat;
    width: 160px;
    height: 120px;

}

.scroos_screenshots {
    width: 24px;
    height: 24px;
    border-radius: 30px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}
.scroos_screenshots  img:hover{
    transform: scale(1.25);
    transition: transform 0.4s;
}
.input_new_screenshots {
    font-size: 12px;
    cursor: pointer;
    width: 160px;
    height: 120px;
    background-color: #05B9BE;
    border-radius: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    text-wrap: nowrap;
    text-align: center;
}
.input_new_screenshots:hover{
    background-color: #009ca0;
}

.input_new_screenshots img {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
    margin-top: 18.75%;
}


.input_new_screenshots input {
    opacity: 0;
}

.paint {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    margin-left: 110px;
}

.textarea_description_work {

    width: 720px;
    height: 120px;
    border: solid 1px #05B9BE;
    padding: 8px 16px;
    gap: 8px;
    border-radius: 20px;
    font-size: 16px;
    color: var(--blue, #1C556E);
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    font-family: "PFBeauSansPro";
    outline: none;

}

.description_work {
    width: 720px;
    margin-left: 110px;
}

.sub_add_work {
    display: flex;
    justify-content: space-around;
}

.add_work__photo__main_photo {
    margin-top: 1%;
    margin-left: 2%;
    color: var(--blue, #1C556E);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
}