.b {
    position: absolute;
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}

textarea:focus
{
  outline: none;
}

h3 {
    margin: 0px;
}

.name {
    margin: 0px 0px 10px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.time {
    font-family: "PFBeauSansProBold";
    color: #767676;
}

.line {
 margin: 10px 0px;
}

.bigLine {
    position: absolute;
    left: 265px;
    top: 10px;
    height: 99%;
}

.leftMenu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content:flex-start;
    align-items: center;
    
    position: absolute;
    top: 10px;
    left: 10px;
    width: 250px; 
    height: 99%;

}

.addNote {
    position: absolute;
    left: 0;
    top:0;
    width: 100%;

    height: 50px;
}

.addNoteBtn {
    height: 35px;
    width: 90%;
    
    background-color: #F7C90E;
    border: 0px;
    border-radius: 20px;
    font-family: "PFBeauSansProBold";
    font-size: 15px;

    margin: 4% 5%;
}

.addNoteBtn:hover {
    background-color: #ffd52b;
    filter: drop-shadow(0 0 0.75rem rgba(220, 199, 43, 0.518));
}

.allNotes {
    position:absolute;
    top: 55px;
    left: 0;
    width: 100%;
    height: 90%;
    overflow-y: auto;
    
    -ms-overflow-style: none;
    scrollbar-width: none;

    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start
   
}

.allNotes::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.note {
    font-family: "PFBeauSansProBold";
    font-size: 15px;
    width: 100%; 
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.noteText {
    position: absolute;
    top: 10px;
    left: 270px;
    
    min-width: fit-content;
    width: 60%;
    height: 95%;
    
    display: flex;
    flex-direction: column;   
    justify-content: flex-start;
    align-items: flex-start;
}

.delBtn {
    width: 190px;
    height: 40px;
    background-color: #EA3C34;
    font-family: "PFBeauSansProBold";
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 20px;
    position: absolute;
    
    right: 20px;
    bottom: 20px;
}

.delBtn:hover {
    background-color: #ff4b41;
    filter: drop-shadow(0 0 0.75rem rgba(255, 48, 48, 0.518));
}

.inp {
    border: 0px;
    border-radius: 0px;
    margin: 0px 0px 10px 0px;

    min-width: fit-contents;
    width: 90%;
    height: fit-content;
    min-height: 30px;

    font-family: "PFBeauSansProBold";
    font-size: 25px;

    resize: none;
}

.area {
    border: 0px;
    border-radius: 0px;
    min-width: fit-content;
    width: 90%;
    font-family: "PFBeauSansProLight";
    font-size: 20px; 
    height: 90%;
    resize: none;
}

.el::-webkit-scrollbar {
    width: 0;
    height: 0;
}