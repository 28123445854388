.SubmitButton {
    cursor: pointer;
    border-radius: 20px;
    color: #2B2A29;
    background: var(--yellow-priority, #F7C90E);
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    border: 0px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.SubmitButton:hover {
    background: var(--yellow-secondary, #EABC02);
    ;
}
.SubmitButton:active{
    background: #E1B502;
}