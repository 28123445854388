@font-face {
    font-family: "PFBeauSansPro";
    font-style: normal;
    font-weight: 300;
    src:
        local("PFBeauSansPro"),
        url('../style/fonts/PFBeauSansPro-Light.ttf');
}

body {
    
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
}

#avatar {
    width: 180px;
    height: 180px;
    border-radius: 100%;
    object-fit: cover;
    grid-column-start: 1;
    grid-column-end: 1;

}

#change_button {
    margin-left: 250px;
    margin-top: 150px;
    width: 35px;
    position: absolute;
    left: 140px;
    top: 185px;
    -webkit-transition: -webkit-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
}

#change_button:hover {
    filter: brightness(85%);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    scale: 1.09;

}

#personal_data {
    display: flex;
    
align-items: center
    
}

#personal_data2 {
    padding-left: 0px;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


#paper_plane {
    width: 45%;
    position: absolute;
    right: 0;
    top: 90px;
}

#small_paper_plane {
    width: 46%;
    scale: 0.7;
    position: absolute;
    right: 0;
    display: none;
    top: 90px;
    margin-right: -35px;
}

#Select_module {
    width: 23%;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #000000;
    font-family: "PFBeauSansProLight";
    font-size: 15px;
}

#select_add_grid {
    margin-top: 7%;
    width: 96%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 2%;
    margin-right: 2%;
}


.Work_Screen_button {
    padding: 0px 25px;
    height: 36px;

    background-color: #D9D9D9;
    border: 0cm;
    border-radius: 20px;
    font-size: 15px;
    font-family: "PFBeauSansProLight";
}

#Work_Screen {
    object-fit: cover;
    width: 250px;
    height: 250px;
}

#foot {
    background-color: #05B9BE;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#footLink {
    color: #FFFFFF;
    font-size: 17px;
    font-family: "PFBeauSansProBold";
    margin-left: 15px;
}

.container {
    font-family: "PFBeauSansProBold";
    box-sizing: border-box;
    max-width: 1180px;
    margin: 58px auto;
    align-items: center;
}

.page_name_my_profile {
    font-family: "PFBeauSansProBold";
    font-size: 40px;
    line-height: 44px;
    font-weight: 600px;
    text-align: left;
}

.page_name_my_gallery {
    font-family: "PFBeauSansProBold";
    font-size: 24px;
    line-height: 44px;
    font-weight: 600px;
    text-align: left;
    margin-top: 40px;
}

.middle-container {
    margin-top: 80px;
    display: flex;
}

