.modal__wrapper_textarea {
    border-radius: 20px;
    border: 1px solid var(--blue-priority, #05B9BE);
    background: #FFF;
    display: flex;
    width: 736px;
    height: 200px;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 8px;
    font-family: "PFBeauSansPro";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    /* 150% */
}
.modal__wrapper-module{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal__wrapper-module> *{
    margin-bottom: 20px;
}
.modal__wrapper-module > div{
    color: black;
}
.error {
    border-color: red;
    color: #EF4545;
    background-color: #FFE5E1;
  }