.search {
  display: grid;
  gap: 20px;
  grid-template-columns: auto 1fr;
}

.search__title {
  color: var(--gray-color-black, #2b2a29);
  font-family: PF BeauSans Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  width: 100%;
}

/* Стили выпадающего списка */
.dropdown {
  position: relative;
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 356px;
}

.dropdown__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 356px;
  height: 48px;
  padding: 8px 20px;
  margin: 0;
  gap: 8px;
  border-radius: 20px;
  border: none;
  outline: 1px solid var(--blue-priority);

  background: var(--gray-color-white);
  color: var(--gray-color-black, #2b2a29);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  transition: outline 0.2s;
}

.dropdown__button[aria-expanded='true'],
.dropdown__button:hover {
  outline: 2px solid var(--blue-priority);
  transition: outline 0.2s;
}

.dropdown__button svg {
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
}

.dropdown__button[aria-expanded='true'] svg {
  transform: rotateX(3.142rad);
  transition: all 0.3s ease-out;
}

.dropdown__box {
  position: absolute;
  top: 90px;
  width: 358px;
  /* height: 242px; */
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid var(--blue-priority);
  background: #fff;
  z-index: 10;
  opacity: 1;
  transition: opacity 0.2s;
}

/* .dropdown__box[aria-hidden="true"] {
  opacity: 1;
  transition: opacity 0.2s;

}
.dropdown__box[aria-hidden="false"] {
  opacity: 0;
  transition: opacity 0.2s;

} */

.dropdown__box::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.dropdown__scroll-box {
  border-radius: 0px 0px 12px 12px;
}
.dropdown__list {
  margin: 0;
  padding: 0;
  max-height: 192px;
  min-height: 48px;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  /* overflow: auto;
  overflow-x: hidden; */
  list-style: none;
  border-radius: 0px 0px 12px 12px;
}

.dropdown__item {
  list-style: none;
  height: 48px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.dropdown__checkbox {
  display: none;
}

.dropdown__label {
  width: 100%;
  height: 100%;
  padding: 12px 16px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  gap: 12px;
  color: var(--gray-color-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  border-bottom: 1px solid var(--gray-color-gray-200, #d9dada);
  transition: background 0.2s;
}

.dropdown__label:hover {
  background: var(--gray-color-gray-100, #efefef);
  transition: background 0.2s;
}

.dropdown__label span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}
.dropdown__label svg {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
}
.box {
  padding-right: 16px;
}
/* .dropdown__list::-webkit-scrollbar {
  width: 24px;
}

.dropdown__list::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
}

.dropdown__list::-webkit-scrollbar-thumb {
  border-radius: 100px;
  height: 56px;
  border: 8px solid transparent;
  background-clip: content-box;
  background-color: var(--gray-color-gray);
} */

/* Стили Поисковой строки */
.search__search-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
}
.search__search-wrapper .search__title {
  grid-row-start: 1;
  grid-column: 1 / span 2;
}

/* общий стиль поисковой строки */
.search-field {
  position: relative;
  box-sizing: border-box;
  border: none;
}
.search-field__input {
  box-sizing: border-box;
  width: 100%;
  padding: 12px 32px 12px 44px;
  background: #fff;
  border: none;
  color: var(--gray-color-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  background-image: url(../../../images/icons/search-24.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 16px 50%;
}
.search-field__clean {
  margin: 0;
  padding: 0;
  position: absolute;
  content: '';
  top: 14px;
  right: 16px;
  height: 20px;
  width: 20px;
  border: none;
  outline: none;
  background: 0 center no-repeat
    url(../../../images/icons/close-20-blue-priority.svg);
  background-size: 20px;
  transition: all 0.2s;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.2s;
}

.search-field__clean:hover {
  transform: scale(1.3);
  transition: transform 0.2s;
}

.search-field__input::placeholder {
  color: var(--gray-color-gray);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
.search-field__input::-webkit-search-cancel-button,
.search-field__input::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
.search-field__input:focus::placeholder {
  color: transparent;
}
.search-field__input:focus::-webkit-input-placeholder {
  color: transparent;
  background: none;
}
.search-field__input:focus:-moz-placeholder {
  color: transparent;
  background: none;
} /* FF 4-18 */
.search-field__input:focus::-moz-placeholder {
  color: transparent;
  background: none;
} /* FF 19+ */
.search-field__input:focus:-ms-input-placeholder {
  color: transparent;
  background: none;
} /* IE 10+ */

.dropdown__search-field input {
  width: 356px;
  height: 48px;
  align-items: center;
  display: flex;
  border-radius: 12px 12px 0px 0px;
  border: none;
  border-bottom: 1px solid var(--blue-priority);
}

.dropdown__search-field input:focus,
.dropdown__search-field input:hover {
  border: none;
  border-bottom: 1px solid var(--blue-priority);
  outline: none;
}

.search__search-fieldr input {
  border-radius: 20px;
  outline: 1px solid var(--blue-priority);
  transition: outline 0.2s;
}

.search__search-fieldr input:focus,
.search__search-fieldr input:hover {
  border: none;
  outline: 2px solid var(--blue-priority);
  border: none;
  transition: outline 0.2s;
}

/* Стили чипсов*/
.search__chips {
  grid-row-start: 2;
  grid-column: 1 / span 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 4px;
  list-style: none;
}
.search__chip {
  margin: 0;
  padding: 4px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background: var(--blue-priority);
  color: var(--gray-color-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  max-width: 100%;
}

.search__chip-delete {
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;
  width: 16px;
  height: 16px;
  transform: scale(1);
  transition: transform 0.2s;
}
.search__chip-delete:hover {
  transform: scale(1.3);
  transition: transform 0.2s;
}

.search__delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 16px;
  margin: 0;
  border-radius: 20px;
  border: none;
  background: var(--gray-color-gray-100);
  color: var(--blue);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
  cursor: pointer;
}

.search__delete-button svg path {
  fill: var(--blue);
}
.search__delete-button svg {
  transform: scale(1);
  transition: transform 0.2s;
}
.search__delete-button:hover svg {
  transform: scale(1.3);
  transition: transform 0.2s;
}
