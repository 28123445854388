.modal_type_other {
  z-index: 50;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;
  padding-right: 15px;
}

.modal__swipper {
  width: 100%;
  margin-bottom: 5%;
  margin-top: 5%;
  overflow: hidden;
}
