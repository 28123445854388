@font-face {
  font-family: "PFBeauSansPro";
  font-style: normal;
  font-weight: 300;
  src:
    local("PFBeauSansPro"),
    url('../../style/fonts/PFBeauSansPro-Light.ttf');
}

.label_text {
  line-height: 30px;
  font-size: 16px;
  font-family: "PFBeauSansPro";
  justify-content: space-evenly;
  display: flex;
  flex-direction: column
}

.login_input {
  width: 340px;
  height: 48px;
  border: solid 1px #05B9BE;
  padding: 8px, 16px, 8px, 16px;
  gap: 8px;
  border-radius: 20px;
  font-size: 16px;
  color: var(--blue, #1C556E);
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  font-family: "PFBeauSansPro";
  outline: none;
}

.custom_input {
  width: 340px;
  height: 48px;
  border: solid 1px #05B9BE;
  padding: 8px, 16px, 8px, 16px;
  gap: 8px;
  border-radius: 20px;
  font-size: 16px;
  color: var(--blue, #1C556E);
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  font-family: "PFBeauSansPro";
  outline: none;
}





.login_input:focus {
  border: solid 2px #05B9BE;

}

.login_input.focused::placeholder {
  color: transparent;
}

.login_input.error {
  border-color: red;
  color: #EF4545;
  background-color: #FFE5E1;
}

.label_text_big_font {
  margin-top: 20px;
  font-family: "PFBeauSansPro_bold";
  color: var(--Text-Main-Text, #111);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}