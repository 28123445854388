#paper_plane_gallery_right{
    width: 25%;
    position: absolute;
    right: 0;
    top: 90px;
}

#H_gallery{
    text-align: center;
    font-family: "PFBeauSansProBold";
    margin-top: 6%;
    font-size: 36px;
}
#paper_plane_gallery_left{
    width: 25%;
    position: absolute;
    left: 0;
    top: 90px;
}
select{
    width: 23%;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #000000;
    font-family: "PFBeauSansProLight";
    font-size: 15px;
}

#select_module{
    margin-top: 7%;
    width: 96%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 2%;
    margin-right: 2%;
}

.galleryCover {
    padding-right: auto; 
    
    margin-bottom: 3%;
}

.Work_grid{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start; 
    
    margin: 20px 20px 0px 0px;
    
}
.Work_grid:hover{
    scale: 1.02;
    filter: brightness(1.06);
}

.Works_grid{
    width: 120%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
 }
 
#Autor_name{
    
    color: #000000;
    font-family: "PFBeauSansProLight";
    font-size: 15px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#Work_name_module{
    
    color: #000000;
    font-family: "PFBeauSansProLight";
    font-size: 15px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#Autor_name:hover {
    text-decoration: underline;
    cursor: pointer;
} 

#Work_name_module:hover {
    text-decoration: underline;
    cursor: pointer;
}
#Real_autor_name{
    color: #000000;
    font-family: "PFBeauSansProLight";
    font-size: 15px;
    width: 250px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#Real_autor_name:hover{
    text-decoration: underline;
    cursor: pointer;
}
#Work_Screen{
    margin-bottom: 5px;
    object-fit: cover;
    width: 250px;
    height: 250px;
    border-radius: 5px;
}
