a {
  text-decoration: none;
}

.App {
  margin: 0;
}

#combo-box-demo {
  border-width: 3px;
  border-color: red;
}

.TextField {
  border-radius: 3px;
  font-family: 'PFBeauSansProLight';
}

.Autocomplete {
  font-family: 'PFBeauSansProLight';
}

.TextField {
  font-family: 'PFBeauSansProLight';
}

.css-x6i2rx-MuiAutocomplete-root {
  border-color: #000000;
  font-family: 'PFBeauSansProLight';
}

.MuiAutocomplete-root {
  color: #000000;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  font-family: 'PFBeauSansProLight';
  border: #000000;
  color: #000000;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 40px;
  border: #000000;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 40px;
  border: #000000;
}

/* .header {
  margin: 0;
  background-color: #05b9be;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
} */

#center {
  display: flex;
  align-items: center;
  text-align: center;
  width: 704px;
}

/* h1 {
  font-family: 'PFBeauSansProLight';
  font-size: 14px;
  margin-right: 40px;
} */

#Entry_main_label {
  font-family: 'PFBeauSansProBold';
  font-size: 40px;
  line-height: 43px;
}

#Mask {
  width: 33%;
  z-index: 2;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
}

#Crown {
  width: 15%;
  z-index: 1;
  display: flex;
  position: fixed;
  bottom: 45%;
  right: 8%;

  animation-duration: 4s;
  animation-iteration-count: infinite;
}

#prevbutt {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.364);
  padding: 4px;
  padding-left: 6px;
  margin-right: 15px;
  padding-right: 6px;
  border-radius: 100%;
}

#prevbutt:hover {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.685);
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 100%;
}

#nextbutt:hover {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.685);
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 100%;
}

textarea {
  font-family: 'PFBeauSansProLight';
}

#nextbutt {
  align-items: center;
  align-self: center;

  margin-left: 15px;
  background-color: rgba(255, 255, 255, 0.364);
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 100%;
}

.fly {
  animation-name: fly;
  animation-timing-function: linear;
}

@keyframes fly {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0);
  }
}

#Jobs {
  z-index: 2;
  width: 33%;
  display: flex;
  position: fixed;
  top: 40px;
  left: 0;
}

#Bubble {
  z-index: 3;
  width: 10%;
  display: flex;
  position: fixed;
  top: 20%;
  left: 8%;

  transform: scale(0.8);
  animation: pulse 4s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

#Entry_button {
  width: 150px;
  height: 30px;
  margin-top: 25px;
  background: #05b9be;
  border-radius: 50px;
  border: 0px solid #05b9be;
  font-size: 18px;
  font-family: 'PFBeauSansProBold';
  color: #000000;
}

#Entry_button:hover {
  background: #06c3ca;
}

#container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-left: 10%;
}

#login_input {
  margin-top: 20px;

  position: fixed;
  top: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#slideimg {
  height: 250px;
  max-width: 450px;
  object-fit: scale-down;
}

#right {
  margin-left: -200px;
  margin-top: 50px;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr, 1fr;
  align-items: center;
  text-align: center;
  width: 600px;
}

#screenslider {
  grid-column-start: 0;
  grid-column-end: 0;
  grid-row-start: 0;
  grid-row-end: 0;
}

/* button:hover {
  scale: 1.1;
} */

#pass_open {
  margin-top: 8.5px;
  position: absolute;
  margin-left: -33px;
}

#pass_closed {
  margin-top: 13px;
  position: absolute;
  margin-left: -33px;
}

/*
label{
  font-family: "PFBeauSansProLight";
  font-size: 14px;
  padding-bottom: 3px;
  padding-top: 20px;
}*/

.Entry_input {
  width: 220px;
  height: 26px;
  padding-right: 35px;
  background: #ffffff;
  border: 1.5px solid #000000;
  border-radius: 30px;
  padding-left: 10px;
}

.Entry_input2 {
  width: 245px;
  height: 26px;
  padding-right: 10px;
  background: #ffffff;
  border: 1.5px solid #000000;
  border-radius: 30px;
  padding-left: 10px;
}

.Email_password {
  font-family: 'PFBeauSansProLight';
  font-size: 18px;
  padding-bottom: 3px;
  padding-top: 20px;
}

@font-face {
  font-family: 'PFBeauSansProLight';
  src: url(./style/fonts/PFBeauSansPro-Light.ttf);
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'PFBeauSansProBold';
  src: url(./style/fonts/PFBeauSansPro-SemiBold.ttf);
}

/* Стили кнопок */
.button_secondary {
  display: flex;
  gap: 10px;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: var(--blue-priority);
  color: var(--gray-color-white);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: none;
  outline: none;
  width: max-content;
  cursor: pointer;
  transition: outline 0.2s, background 0.2s;
}

.button_secondary:hover {
  background: #009ca0;
  transition: background 0.2s;
}

.button_secondary svg {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
}

.button_secondary_blue {
  background: var(--blue-priority);
  color: var(--gray-color-white);
}
.button_secondary_blue:hover {
  background: #009ca0;
  transition: background 0.2s;
}
.button_secondary_blue:disabled {
  background: var(--blue-priority);
    transition: background 0.2s;
}

.button_secondary_red {
  background: var(--sysyem-color-system-error);
  color: var(--gray-color-white);
}
.button_secondary_red:hover {
  background: var(--red);
  transition: background 0.2s;
}

.button_secondary_white {
  background: var(--gray-color-white);
  color: var(--blue-priority);
}
.button_secondary_white:hover {
  background: #d6fafb;
  transition: background 0.2s;
}
.button_secondary_white:disabled {
  color: var(--gray-color-gray, #9C9C9C);
  transition: color 0.2s;
}
