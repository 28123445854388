#Editwork_peper_plane_right{
    width: 16%;
    position: absolute;
    right: 0;
    top: 75px;
}

#H_edit_work{
    text-align: center;
    font-family: "PFBeauSansProBold";
    margin-top: 4.5%;
    margin-bottom: 3%;
    font-size: 36px;
}
#Editwork_peper_plane_left{
    width: 18%;
    position: absolute;
    left: 0;
    top: 70px;
}

#back {
    margin-left: 25px;
    display: grid;
    height: fit-content;
    align-items: flex-start;
    
    grid-template-columns: 1fr 1fr 1.25fr;
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 10px;
    grid-template-areas: 
    "a b c"
    "d b c"
    "g h c"
    "j j l";
}

#dote{
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: #F7C90E;
    border: 2px solid #000000;
    grid-column-start: 1;
    grid-column-end: 1;

}
#Input_work_name_grid{
    display: grid;
    width: fit-content;
    height: fit-content;
    align-items: flex-start;
    grid-template-columns: 18px 1fr;
    grid-template-rows: 1fr;
    
     
}
#Input_grid{
    display: grid;
    width: fit-content;
    height: fit-content;
    align-items: flex-start;
    grid-template-columns: 18px 1fr;
    grid-template-rows: 1fr;
    
   
}
#Input_grid_share{
    display: grid;
    width: 96%;
    height: fit-content;
    align-items: flex-start;
    grid-template-columns: 18px 1fr 1fr;
    grid-template-rows: 1fr;
    
}
#Input_work_name{
    flex-direction: column;
    display: table-cell;
    vertical-align:top;
    margin-left: 15px;

}
#Edit_work_name{
    height: 35px;
    width: 300px;
    font-family: "PFBeauSansProLight"; 
    text-align: center; 
}

#Edit_work_about {
    resize: none;
    width: 300px;
    height: 200px;
    font-family: "PFBeauSansProLight";
}

#Input_grid_2{
    flex-direction: column;
    display: table-cell;
    vertical-align:top;
    margin-left: 15px;
}
#Input_h{
    font-size: 20px;
    display: flex;
    align-self:center;
    font-family: "PFBeauSansProBold";
    margin-bottom: 15px;
}
#Input{
    font-size: 20px;
    grid-row-start: 1;
    grid-row-end: 1; 
    align-self: flex-start;
    grid-column-start: 2;
    grid-column-end: 2;
    font-family: "PFBeauSansProLight";
}
#Edit_module{
    background: #FFFFFF;
    border: 1px solid #000000;
    font-family: "PFBeauSansProLight";
    font-size: 15px;
    height: 41px;
    width: 308px;
    grid-row-start: 1;
    grid-row-end: 1; 
    align-self: flex-start;
    grid-column-start: 2;
    grid-column-end: 2;
}
#Share{
    margin-left: 17px;
    height: 30px;
    width: 30px;
    align-items: center;

}


#Delete_work{
    justify-self: end;
    margin-right: 50px;
    width: 40%;
    height: 35px;
    background: #EA3C34;
    border-radius: 20px;
    border: none;
    font-size: 15px;
    font-family: "PFBeauSansProBold";
    color: #FFFFFF;
}

#Delete_work:hover {
    background-color: #ff4b41;
    filter: drop-shadow(0 0 0.75rem rgba(255, 40, 40, 0.518));
}

#Done_edit{
    justify-self:center;
    width: 20%;
    margin-left: 40%;
    margin-bottom: 15px;
    height: 35px;
    background: #1CBBC0;
    border-radius: 20px;
    border: none;
    font-size: 15px;
    font-family: "PFBeauSansProBold";
}

#Done_edit:hover {
    background-color: #06c3ca;
    filter: drop-shadow(0 0 0.75rem rgba(38, 180, 205, 0.518));
}

#Screen_grid {
    position: absolute;
    right: 35px;
    top: 310px;
    width: 275px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

