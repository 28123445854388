.custom-radio {

    display: inline-block;
    cursor: pointer;
    color: #1C556E;
    font-size: 20px;
    line-height: 24px;
    padding: 8px 20px 8px 20px;
    gap: 10px;
  }
  
  .checked {
    color: white;
    background-color: #05B9BE;
    border-radius: 30px;
  }
  
  input[type="radio"] {
    display: none;
  }
  
.container-ModuleBar{
    margin-top: 20px;
}