.avatar_form {
  cursor: pointer;
  float:right;
  margin-right: 3.39%;
}

.avatar_img {
  width: 180px;
  height: 180px;
  border-radius: 180px;
}

.avatar_change {
  border: 0;
  padding: 0;
  background: url('../../pages/images/buttonChangeAvatar.svg') no-repeat center center;
  min-width: 48px;
  height: 48px;
  position: absolute;
  transform: translate(-80%, 250%);
  cursor: pointer;
  
}

.avatar_change::before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  margin: 12px;
}