.header {
  width: 100%;
  height: 120px;
  margin: 0 auto;
  background: var(--blue-priority);
  display: flex;
}

.nav {
  max-width: 1440px;
  min-width: 100%;
  padding: 0 130px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(max-content, 1fr));
  gap: 40px;
  align-items: end;
  justify-content: space-between;
}

.nav__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
}
.nav__wrapper_left {
  justify-self: start;
  gap: 40px;
}
.nav__wrapper_left .nav__logo-link {
  align-self: center;
}
.nav__wrapper_center {
  justify-self: center;
}
.nav__wrapper_right {
  justify-self: end;
}

.nav__logo-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav__logo-link svg {
  width: 80px;
  aspect-ratio: 80 / 40.15;
  object-fit: contain;
  margin: 0;
  padding: 0;
  align-self: center;
  transform: scale(1);
  transition: transform 0.2s;
}
.nav__logo-link:hover svg {
  transform: scale(1.05);
  transition: all 0.2s;
}

.nav__link {
  text-decoration: none;
  color: var(--gray-color-white);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  padding: 20px 16px;
  transition: color 0.2s;
  justify-self: end;
}
.nav__link:hover {
  color: var(--yellow-priority);
  transition: all 0.2s;
}

.nav__link.active {
  color: var(--yellow-priority);
  padding-bottom: 16px;
  border-bottom: 4px solid var(--yellow-priority);
  transition: color 0.2s;
}
