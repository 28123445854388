.work {
  margin: 80px auto;
  background: none;
  max-width: 1275px;
  height: max-content;
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 12px;
  position: relative;
  word-break: break-word;
}

.work__container {
  border-radius: 20px;
  background: #fff;
  max-width: 1180px;
  width: 100%;
  margin: 0;
}

.work__header {
  border-radius: 20px 20px 0px 0px;
  background: var(--blue-priority, #05b9be);
  padding: 16px;
  display: flex;
  justify-content: end;
}

.work__close-button {
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.work__close-button svg {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.2s;
}

.work__close-button:hover svg {
  transform: scale(1.3);
  transition: transform 0.2s;
}

.work__content {
  margin: 58px 114px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.work__top-wrapper {
  display: grid;
  gap: 12px;
}

.work__wrapper {
  margin: 0;
  padding: 0;
  max-width: 720px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
}

.work__status {
  align-self: self-end;
  justify-self: end;
  margin: 0;
  padding: 0;
  width: max-content;
  height: min-content;
  display: flex;
}

.work__title {
  margin: 0;
  padding: 0 0 12px;
  color: var(--gray-color-black);
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  text-align: start;
  grid-column: 1 / span 2;
}

.work__module-title {
  color: var(--blue);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  padding: 0;
  text-align: start;
}

.work__description-title {
  color: var(--blue);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: start;
}

.work__description-wparrer {
  width: 600px;
}

.work__description-text {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: var(--blue);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  text-align: start;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.5s;
}

.work__description-text.is-open {
  -webkit-line-clamp: 100;
  transition: all 0.5s;
}

.work__more-button {
  content: 'Подробнее';
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: var(--gray-color-gray);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  transition: color 0.2s;
  cursor: pointer;
}

.work__more-button svg {
  fill: var(--gray-color-gray);
  transition: fill 0.2s;
}

.work__more-button.is-open svg {
  transform: rotateX(180deg);
}

.work__more-button:hover svg {
  fill: var(--blue);
  transition: fill 0.2s;
}

.work__more-button:hover {
  color: var(--blue);
  transition: color 0.2s;
}

.work__slider {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.work__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.work__author {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.work__avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: none;
  background-color: var(--blue-priority);
  margin: 0;
  padding: 0;
}

.work__author-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.work__author-name {
  color: var(--gray-color-black, #2b2a29);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  margin: 0;
  padding: 0;
}

.side-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  list-style: none;
  margin: 0;
  padding: 0;
  align-content: center;
  justify-content: center;
  min-width: max-content;
}

.side-panel__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 4px;
}

.side-panel__button {
  border-radius: 8px;
  background: #fff;
  width: 40px;
  height: 40px;
  border: none;
  padding: 8px;
  margin: 0;
  cursor: pointer;
}

.side-panel__button svg {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.side-panel__button:hover svg path {
  fill: var(--blue, #1c556e);
}

.side-panel__button:hover {
  background: var(--gray-color-gray-100);
}

.side-panel__button-name {
  color: var(--gray-color-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  width: 100%;
}

.work__publish-btn {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 8px;
  height: fit-content;
  color: var(--text-main-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  cursor: pointer;
}

.work__publish-btn svg {
  width: 24px;
  height: 24px;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.2s;
}

.work__publish-btn:hover svg {
  transform: scale(1.3);
  transition: transform 0.2s;
}


.checking_work__title {
  color: var(--Gray-color-black, #2B2A29);
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.checking_work__textarea {
  padding: 8px 16px;
  width: 70%;
  height: 120px;
  border-radius: 20px;
  border: 2px solid var(--blue-priority, #05B9BE);
  background: #FFF;
}

.checking_work_wrapper {
  margin-top: 1%;
  display: flex;
  justify-content: center;
}

.checking_work_wrapper__btn_accept {
  cursor: pointer;
  display: flex;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Gray-color-white, #FEFEFE);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: var(--Sysyem-color-system-sucess, #00BE64);
  border: 0;
  border-radius: 20px;
  margin-right: 12px;
}

.checking_work_wrapper__btn_accept:hover {
  background: var(--Sysyem-color-system-sucess, #01562e);
}

.checking_work_wrapper__btn_reject {
  cursor: pointer;
  display: flex;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Gray-color-white, #FEFEFE);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 20px;
  border: 0;
  background: var(--Sysyem-color-system-error, #EF4545);
}

.checking_work_wrapper__btn_reject:hover {
  background: var(--Sysyem-color-system-error, #ae3333);
}