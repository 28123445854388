.modal {
  width: 100%;
  height: 100%;
  background: rgba(43, 42, 41, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  /* display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center; */
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  overflow: auto;
}

.modal_active {
  opacity: 1;
  pointer-events: all;
}

.moda_type_question {
  z-index: 100;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.modal__container {
  margin: 0 auto;
  padding: 0;
  border-radius: 20px;
  background: #fff;
  width: 100%;
}

.modal__container_question {
  max-width: 460px;
}

.modal__header {
  border-radius: 20px 20px 0px 0px;
  background: var(--blue-priority, #05b9be);
  padding: 16px;
  display: flex;
  justify-content: end;
}

.modal__close-button {
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.modal__close-button svg {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.2s;
}

.modal__close-button:hover svg {
  transform: scale(1.3);
  transition: transform 0.2s;
}

.modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 40px 60px;
}

.modal__buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: max-content;
  align-items: center;
}

.modal__buttons_row {
  flex-direction: row;
}

.modal__buttons_column {
  flex-direction: column;
}

.modal__question {
  color: var(--gray-color-black, #2b2a29);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  word-break: break-word;
  margin: 0;
  padding: 0;
  text-align: center;
}

.modal__question-detailed {
  color: var(--gray-color-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
  padding: 0;
  word-break: break-word;
  margin-top: 12px;
  text-align: center;
}

.modal__buttons {
  margin-top: 20px;
}

.textarea {
  border-radius: 20px;
  border: 1px solid var(--blue-priority, #05B9BE);
  background: #FFF;
  display: flex;
  height: 120px;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 317px;
  height: 120px;
  margin: 4px 0 40px 0;
}