.name_input {
  width: 680px;
  height: 48px;
  border: solid 1px #05B9BE;
  padding: 8px, 16px, 8px, 16px;
  gap: 8px;
  margin-right: 8px;
  border-radius: 20px;
  color: var(--gray-color-gray, #9C9C9C);
  font-family: "PFBeauSansPro";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 150% */

}

.title_submit {
  font-weight: bold;
  border-radius: 20px;
  background-color: #05B9BE;
  color: white;
  font-size: 20px;
  border: 0px;
  width: 139px;
  padding: 12px, 40px, 12px, 40px;
  height: 48px;
  margin-right: 73px;
}

.container_SearchInput {
  display: flex;

}
.visible{
  display: none;
}

.input-container {
  height: 48px;
  width: 680px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  margin-right: 8px;
}

.name_input {
  border: solid 1px #05B9BE;
  border-radius: 20px;
  max-width: 680px;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  box-sizing: border-box;
  padding-left: 40px;
  z-index: 2;
  color: var(--gray-color-gray, #9C9C9C);
  font-family: 'PFBeauSansPro';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.search-icon {
  padding-left: 8px;
  position: absolute;
  transform: translate(0, -50%);
  top: 46%;
  left: 8px;
  z-index: 2;
  transition: 0.5s;
}

.search_input {
  box-sizing: border-box;
  width: 680px;
  padding: 12px 32px 12px 44px;
  background: #fff;
  border: none;
  color: var(--gray-color-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  background-image: url(../../images/icons/search-24.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 16px 50%;
}

.search_field{
  display: flex;
}
.search_field input{
  margin-right: 8px;
}
.search_field button{
  margin-right: 73px;
}