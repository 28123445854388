.images__wrapper {
  position: relative;
}

.images__swiper {
  max-width: 952px;
  max-height: 440px;
  aspect-ratio: 952 / 440;
  border-radius: 20px;
}

.images__swiper .swiper-slide:hover::after {
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  content: '';
  background-image: url(../../../images/icons/arcticons_photo-and-picture-resizer.svg);
  background-size: 28px 28px;
  background-repeat: no-repeat;
  bottom: 20px;
  right: 20px;
  background-position: right bottom;
  z-index: 100;
  opacity: 1;
  visibility: visible;
  transition: all 0.2s;
}

.images__swiper .swiper-slide::after {
  visibility: hidden;
  transition: all 0.2s;
}

.images__nav-swiper {
  width: 100%;
  border-radius: 20px;
}
.images__nav-swiper .swiper-slide {
  max-width: 304px;
  max-height: 200px;
  aspect-ratio: 304 / 200;
  border-radius: 20px;
}
.images__nav-swiper .swiper-slide .images__image {
  opacity: 0.5;
}
.images__nav-swiper .swiper-slide:hover .images__image {
  opacity: 1;
  border: 1px solid var(--Secondary-Accent-Blue-Dark);
}
.images__nav-swiper .swiper-slide-thumb-active .images__image {
  border: 1px solid var(--Primary-Accent);
  opacity: 1;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-sizing: border-box;
}

.images__image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: #d9d9d9cc;
  object-fit: contain;
}

.images__btn {
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  width: 24px;
  height: 24px;
  position: absolute;
  top: calc(50% - 12px);
  z-index: 20;
  transform: scale(1);
  transition: transform 0.2s;
  cursor: pointer;
}
.images__btn_prev {
  left: -36px;
}
.images__btn_next {
  right: -36px;
}
.images__btn:hover {
  transform: scale(1.3);
  transition: all 0.2;
}

.modal__swiper {
  max-height: 90vh;
}

.modal__swiper .images__image {
  max-height: 90vh;
  object-fit: contain;
}

.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-horizontal > .swiper-pagination-bullets {
  border-radius: 16px;
  background: rgba(250, 250, 255, 0.3);
  padding: 12px 8px;
  max-width: max-content;
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 16px;
  gap: 8px;
}
.swiper-pagination-bullet {
  background: #d6fafb;
  width: 12px;
  height: 12px;
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.03),
    0px 1px 1px 0px rgba(0, 0, 0, 0.01), 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
}
.swiper-pagination-bullet:hover {
  background: #009ca0;
}

.swiper-pagination-bullet-active {
  background: var(--blue-priority);
  color: var(--blue-priority);
}

.swiper-vertical > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-vertical {
  right: 5px;
  width: 10px;
}

/* .swiper-button-prev,
.swiper-button-next {
} */

.swiper-button-prev:after,
.swiper-button-next:after {
  content: '';
}

.modal__swiper .swiper-wrapper {
  visibility: hidden;
}
/* .modal__swiper .swiper-slide {
} */
.modal__swiper .swiper-wrapper .swiper-slide {
  visibility: visible;
}

.modal__swiper .swiper-button-next,
.modal__swiper .swiper-button-prev {
  background-repeat: none;
  background-size: 50px;
  width: 50px;
  height: 50px;
  background-color: #d6fafbcc;
  border-radius: 100%;
  transform: scale(1);
  transition: transform 0.2s;
}

.modal__swiper .swiper-button-next {
  background-image: url(../../../images/icons/arrow_forward_ios.svg);
  right: 30px;
}
.modal__swiper .swiper-button-prev {
  background-image: url(../../../images/icons/arrow_back_ios_new.svg);
  left: 30px;
}

.modal__swiper .swiper-button-next:hover,
.modal__swiper .swiper-button-prev:hover {
  transition: all 0.2s;
  transform: scale(1.3);
}

.modal__close {
  position: absolute;
  top: 5%;
  right: 50px;
  z-index: 10;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  transform: scale(1);
  transition: transform 0.2s;
}
.modal__close svg {
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.modal__close svg circle {
  opacity: 0.8;
}

.modal__close:hover {
  transition: all 0.2s;
  transform: scale(1.3);
}
