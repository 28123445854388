.cont_modal-add-student {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  align-items: center;
  
  
}

.modal__close_img:hover {
  transform: scale(1.3);
  transition: transform 0.2s;
}


.modal {
  width: 100%;
  height: 100%;
  background: rgba(43, 42, 41, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  overflow: auto;
}

.modal_active {
  opacity: 1;
  pointer-events: all;
}

.moda_type_question {
  z-index: 100;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.modal__container {
  margin: 0 auto;
  padding: 0;
  border-radius: 20px;
  background: #fff;
  width: 100%;
}

.modal__container_question_add_student {
  max-width: 1000px;
}

.modal__header {
  border-radius: 20px 20px 0px 0px;
  background: var(--blue-priority, #05b9be);
  padding: 16px;
  display: flex;
  justify-content: end;
}

.modal__close-button {
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.modal__close-button svg {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.2s;
}

.modal__close-button:hover svg {
  transform: scale(1.3);
  transition: transform 0.2s;
}

.modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 40px 60px;
}

.modal__buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: max-content;
  align-items: center;
}

.modal__buttons_row {
  flex-direction: row;
}

.modal__buttons_column {
  flex-direction: column;
}

.modal__question_add_student {
  word-break: break-word;
  margin: 0;
  padding: 0;
  text-align: center;
  color: var(--Gray-color-black, #2B2A29);
  margin-bottom: 40px;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  /* 110% */
}

.modal__question-detailed {
  color: var(--gray-color-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
  padding: 0;
  word-break: break-word;
  margin-top: 12px;
  text-align: center;
}

.modal__buttons {
  margin-top: 20px;
}

.modal__calendar_input {
  width: 340px;
  height: 48px;
  border: solid 1px #05B9BE;
  padding: 8px, 16px, 8px, 16px;
  gap: 8px;
  border-radius: 20px;
  font-size: 16px;
  color: var(--blue, #1C556E);
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  font-family: "PFBeauSansPro";
  outline: none;
}

.modal__calendar_input_calendar {
  width: 412px;
  height: 48px;
  border: solid 1px #05B9BE;
  padding: 8px, 16px, 8px, 16px;
  gap: 8px;
  border-radius: 20px;
  font-size: 16px;
  color: var(--blue, #1C556E);
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  font-family: "PFBeauSansPro";
  outline: none;
}
.modal__calendar_input_calendar:focus {
  border: solid 2px #05B9BE;

}


input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url('../../../../images/icons/calendar_month_24.svg');
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}

.modal__generate_password {
  cursor: pointer;
  margin-top: 5.2%;
  
  color: white;
  font-family: "PFBeauSansPro_bold";
  display: flex;
  font-size: 20px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0;
  border-radius: 20px;
  background: var(--blue-priority, #05B9BE);
  color: var(--Gray-color-white, #FEFEFE);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-right: 12%;
  
  width: 300px;
  height: 49px;

}

.modal__wrapper-flex {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.modal__wrapper_sub-btn {
  margin-top: 3%;

}